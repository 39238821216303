export default [
  {
    key: 'no',
    label: 'field.invoiceNo',
    type: 'text',
    operator: '%',
  },
  {
    key: 'postingDate',
    label: 'field.postingDate',
    type: 'date-range-picker',
    cast: 'range',
    firstOperator: 'd>=',
    secondOperator: 'd<=',
  },
];
